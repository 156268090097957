<template>
  <div>
    <present-dish-form :save='save' class='mt-10' />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PresentDishForm from './present-dish-form.vue'

export default {
  name: 'PresentDishAdd',
  components: { PresentDishForm },
  computed: {},
  async created() {
    document.title = 'Создание товара'
    await this.resetForm()
  },
  methods: {
    async save(image) {
      await this.create(image)
      this.$router.back()
    },
    ...mapActions('customDishes/form', ['create', 'resetForm']),
  },
}
</script>
