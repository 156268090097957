<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <image-upload v-model="image" :width="375" :height="204" @formData-updated="imageUploaded"
                    @file-removed="imageRemoved"></image-upload>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-form class="multi-col-validation mt-6" ref="form">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model.number="rkId"
              :rules="[rules.required]"
              dense
              outlined
              label="Идентификатор Rk7"
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            md="12"
            cols="24"
          >
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <v-tab v-for="(item, index) in translations" :key="index">
                <span>{{ item.language }}</span>
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(item, index) in translations" :key="index">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.name"
                    label="Наименование"
                    dense
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-col cols="12">
            <v-btn
              @click="saveData"
              color="primary"
              class="me-3 mt-4"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions } from 'vuex'
import { rules } from '@/helpers/validation'
import ImageUpload from '@/components/image-upload.vue'
import { hasErrorsForProperty, errorMessages } from '@/helpers'

export default {
  name: 'PresentDishForm',
  props: ['save', 'show'],
  components: { ImageUpload },
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    tab: null,
    rules,
    newImage: null,
    hasErrorsForProperty,
    errorMessages,
  }),
  computed: {
    ...mapFormHandlers('customDishes/form', [
      'translations',
      'id',
      'rkId',
      'image',
      'type',
      'applianceTypes',
    ]),
  },
  methods: {
    imageUploaded(blob) {
      this.newImage = blob
    },
    imageRemoved() {
      this.newImage = null
    },
    cancel() {
      this.resetForm()
      this.$router.back()
    },
    ...mapActions('customDishes/form', ['resetForm']),
    async saveData() {
      if (this.$refs.form.validate()) {
        this.type = 'Present'
        this.applianceTypes = null
        await this.save(this.newImage)
      }
    },
  },
}
</script>
